svg>polygon {
    fill: #39b54a;
    fill-opacity: .7;
}

svg[selected="true"]>polygon {
    fill: #57899e;
}

svg[selected="false"][busy="true"]>polygon {
    fill: #f4d33a;
}

svg[selected="false"][disabled="true"]>polygon {
    fill: #869291;
    fill-opacity: .7;
}

svg[selected="false"][disabled="true"]>line {
    stroke: #869291;
    stroke-width: 4;
    stroke: 10, 10;
}

svg[selected="true"]>circle {
    fill: #fff;
    stroke: #57899e;
    stroke-width: 4;
    display: block;
}

svg>circle {
    display: none;
}

svg[selected="true"]>line {
    stroke: #57899e;
    stroke-width: 4;
}

svg[selected="false"][busy="true"]>line {
    stroke: #f4d33a;
    stroke-width: 4;
}

svg>line {
    stroke: #39b54a;
    stroke-width: 4;
    stroke: 10, 10;
}

svg[type="Circulo"][free="true"]>circle {
    fill: #39b54a;
    fill-opacity: .7;
    stroke: #39b54a;
    stroke-width: 4;
    display: block;
}



svg[type="Circulo"][disabled="true"]>circle {
    fill: #869291;
    fill-opacity: .7;
    stroke: #869291;
    stroke-width: 4;
    display: block;
}

svg[type="Circulo"][selected="true"]>circle {
    fill: #57899e;
    fill-opacity: .7;
    stroke: #57899e;
    stroke-width: 4;
    display: block;
}

svg[type="Circulo"][selected="false"][busy="true"]>circle {
    fill: #f4d33a;
    fill-opacity: .7;
    stroke: #f4d33a;
    stroke-width: 4;
    display: block;
}