svg>polygon {
    fill: #57899e;
    fill-opacity: .7;
}

svg[editing="true"]>polygon {
    fill: #57899e;
}

svg[editing="true"]>circle {
    fill: #fff;
    fill-opacity: .7;
    stroke: #57899e;
    stroke-width: 4;
    display: block;
}

svg>circle {
    display: none;
}

svg[editing="true"]>line {
    stroke: #57899e;
    stroke-width: 4;
    stroke-dasharray: 10, 10;
}

svg>line {
    stroke: #39b54a;
    stroke-width: 4;
    stroke: 10, 10;
}

svg[type="Circulo"]>circle {
    fill: #39b54a;
    fill-opacity: .7;
    stroke: #39b54a;
    stroke-width: 4;
    display: block;
}

svg[type="Circulo"][editing="true"]>circle {
    fill: #57899e;
    fill-opacity: .7;
    stroke: #3a6577;
    stroke-width: 4;
    display: block;
    stroke-dasharray: 10, 10;
}