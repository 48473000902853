html {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

p.error {
  color: red;
  padding: 0 10px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}